<template>
    <div class="follow-container">
        <div class="follow-content">
            <div class="follow-title">黄浦区人文行走导学平台</div>
            <div class="follow-logo"></div>
            <div class="follow-button">扫码关注微信公众号</div>
        </div>
    </div>
</template>

<script>
export default {
    name: "follow",
};
</script>

<style lang="scss">
.follow-container {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    .follow-content {
        text-align: center;
        .follow-title {
          font-size: 38px;
          color: #333;
        }
        .follow-logo {
            width: 280px;
            height: 280px;
            border-radius: 20px;
            background: url("http://oss.hkrin.com/userInfo/10/20211114094734qrcode_rw.jpg") no-repeat;
            background-size: 100% 100%;
            margin: 80px auto 0;
        }
        .follow-button {
          font-size: 35px;
          margin-top: 50px;
          color: #b72e32;
        }
    }
}
</style>